import UserForm from '@/components/ManageUsers/UserForm/UserForm.vue';
import UPDATE_USER_MUTATION from '@/graphql/mutations/updateUser.gql';
import USERS_QUERY from '@/graphql/queries/users.gql';
import eventHub from '@/utils/eventHub';

export default {
  name: 'EditUser',
  components: { UserForm },
  props: {
    data: { type: Object, default: null },
  },
  data() {
    return {
      UPDATE_USER_MUTATION,
      user: (this.data && { ...this.data }) || {
        username: null,
        email: null,
        role: null,
        client: null,
        permissions: null,
      },
    };
  },
  methods: {
    updateUsers(store, { data: { updateUser } }) {
      if (updateUser) {
        // Read the data from our cache for this query.
        const data = store.readQuery({
          query: USERS_QUERY,
        });
        const index = data.users.findIndex(u => u.id === updateUser.id);
        if (index !== -1) {
          data.users[index] = updateUser;
          data.users = [...data.users];
          store.writeQuery({
            query: USERS_QUERY,
            data: data,
          });
        }
        eventHub.$emit('show-snackbar', {
          color: 'success',
          text: `User ${updateUser.username} was edited successfully!`,
        });
      } else {
        eventHub.$emit('show-snackbar', {
          color: 'error',
          text: `Error! Something went wrong... Please try later!`,
        });
      }
    },
  },
};
