import PermissionList from './PermissionList/PermissionList.vue';
import PERMISSIONS_DICTIONARY_QUERY from '@/graphql/queries/permissionsDictionary.gql';
export default {
  name: 'Permissions',
  components: { PermissionList },
  model: {
    prop: 'permissions',
    event: 'update',
  },
  props: {
    permissions: { type: Array, default: null },
  },
  data: () => ({
    PERMISSIONS_DICTIONARY_QUERY,
  }),
};
