import ExpansionPanelHeader from '@/components/ExpansionPanelHeader/ExpansionPanelHeader.vue';
import ExpansionPanelContent from '@/components/ExpansionPanelContent/ExpansionPanelContent.vue';
import DefinitionList from '@/components/DefinitionList/DefinitionList.vue';
import { PERMISSION_LABELS, PERMISSION_GROUP_LABEL } from '@/enums/permissions';

export default {
  name: 'PermissionList',
  components: { ExpansionPanelHeader, ExpansionPanelContent, DefinitionList },
  model: {
    prop: 'permissions',
    event: 'update',
  },
  props: {
    disabled: { type: Boolean, default: false },
    permissions: { type: Array, default: null },
    permissionsDictionary: { type: Array, default: [] },
  },
  data: function() {
    return { selected: this.permissions ? this.permissions.map(p => p.key) : [] };
  },
  computed: {
    displayedPermissions() {
      return Object.entries(
        this.permissionsDictionary.reduce((g, p) => {
          if (!g[p.group]) {
            g[p.group] = [];
          }
          g[p.group].push({ ...p, label: PERMISSION_LABELS[p.key] || p.key, disabled: !this.disabled });
          return g;
        }, {})
      ).map(([key, val]) => {
        return {
          group: PERMISSION_GROUP_LABEL[key] || key,
          items: val,
        };
      });
    },
  },
  watch: {
    permissions(value) {
      this.selected = value ? value.map(p => p.key) : [];
    },
  },
  methods: {
    onUpdate() {
      if (this.disabled) {
        const permissions = this.permissionsDictionary.filter(p => this.selected.includes(p.key));
        this.$emit('update', permissions);
      }
    },
  },
};
