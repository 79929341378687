import Vue from 'vue';
import { VExpansionPanelContent } from 'vuetify/lib';
// Create Base Mixins and Define Custom Properties
const base = Vue.extend({ mixins: [VExpansionPanelContent] });
export default base.extend({
  name: 'ExpansionPanelContent',
  components: {
    VExpansionPanelContent,
  },
  props: {
    noGutters: { type: Boolean, default: false },
  },
});
