import { render, staticRenderFns } from "./PermissionList.vue?vue&type=template&id=e514e648&scoped=true&"
import script from "./PermissionList.js?vue&type=script&lang=js&"
export * from "./PermissionList.js?vue&type=script&lang=js&"
import style0 from "./PermissionList.scss?vue&type=style&index=0&id=e514e648&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e514e648",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
installComponents(component, {VCheckbox,VExpansionPanel,VExpansionPanels})
